// 
// user.js
// Use this to write your custom JS
//

/**
 * Sets a cookie value.
 * @param {string} name The name of the cookie to set.
 * @param {string} value The value to save in the cookie.
 * @param {number?} maxAge The maximum age of the cookie in seconds.
 */
function setCookie(name, value, maxAge) {
    var expires = '';
    if (maxAge) {
        expires = '; max-age=' + maxAge;
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

/**
 * Gets a cookie value.
 * @param {string} name The name of the cookie to get.
 * @returns The stored value of the cookie
 */
function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function updateAffiliateCode() {
    var urlAffiliate = window.location.pathname.replaceAll('/', '');
    var cookieAffiliate = getCookie('affiliate');

    if (cookieAffiliate) {
        document.getElementById('refcode').value = cookieAffiliate;
    } else if (urlAffiliate) {
        document.getElementById('refcode').value = urlAffiliate;

        // Remember the affiliate code for 2 hours.
        setCookie('affiliate', urlAffiliate, 60 * 60 * 2);
    }
}

async function getPromotionCodeInfo() {
    const currentCode = document.getElementById('refcode').value.toLowerCase();
    const siteCode = document.getElementById('sitecode').value;
    
    const campaignResponse = await fetch(`${cpurl}/api/CampaignInformation?code=${currentCode}&site=${siteCode}`);
    const data = await campaignResponse.json();

    if (typeof data === 'number') {
        document.querySelectorAll('.price').forEach(ele => {
            ele.innerText = `$${data} USD`;
        });
    }

    var codes = [
        'BeachHS',
        'MercerMS',
        'SavannahEC',
        'WChathamMS',
        'AllanFDailyHS',
        'EJTollMS',
        'GlendaleHS',
        'WoodWilsonMS',
        'DakotaMS',
        'JAdamsMS',
        'JMarshallHS',
        'MayoHS',
        'AllderdiceHS',
        'CarrickHS',
        'Schiller6-8',
        'Sterrett6-8',
        'BallCreekMS',
        'CrestwoodMS',
        'FrederickHS',
        'TuscaroraHS',
    ].map(c => c.toLowerCase());

    if (codes.includes(currentCode) || data === 0) {
        document.getElementById('checkout').innerHTML = 'Checkout for Free <i class="bi bi-stars ms-3"></i>';
        document.getElementById('price-oc').innerHTML = 'Free of charge for your school';
        document.getElementById('price-faq').innerHTML = 'The SAGE-SR is free for your school.';
        document.querySelectorAll('.price').forEach(ele => {
            ele.innerText = 'Free!';
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    updateAffiliateCode();
    getPromotionCodeInfo();
});
